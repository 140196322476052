<template>
    <div class="MonthlyTrend">

        <h2>Monthly Trend</h2>

        <div class="flexedRow">
            <div class="controls">
                <v-select
                class='yearButton'
                :items="years"
                :label='`${year_selected}`'
                v-model='year_selected'
                solo
                dense
                color='rgb(22, 163, 148)'
                ></v-select>
            </div>
            <div class="counts">
                <p><span>{{ yearlyTotals.webinarsEvents }}</span> Webinars</p>
                <p><span>{{ yearlyTotals.enrolledUsers }}</span> Enrolled Users</p>
                <p><span>{{ yearlyTotals.attendedUsers }}</span> Attended Users</p>
            </div>
        </div>

        <LineChartGenerator
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        />
    </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)

export default {

    name: 'MonthlyTrend',
    components: {
        LineChartGenerator
    },
    props: {
        chartId: {
            type: String,
            default: 'line-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        },
        year_selected: {
            required: true,
            type: Number
        },
        monthlyTrends: {

        }
    },
    data() {
        return {
            chartData: {
                labels: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                ],
                datasets: [
                    {
                        type: 'line',
                        label: 'Attended Users',
                        backgroundColor: 'rgb(235, 225, 17)',
                        borderColor: 'rgb(235, 225, 17)',
                        // data: [14, 4, 12, 2, 3, 14, 4, 4, 0, 0, 0, 0]
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    },
                    {
                        type: 'line',
                        label: 'Enrolled Users',
                        backgroundColor: 'rgb(82, 204, 160)',
                        borderColor: 'rgb(82, 204, 160)',
                        // data: [28, 7, 25, 4, 6, 28, 8, 8, 0, 0, 0, 0]
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    },
                    {
                        type: 'bar',
                        label: 'Events',
                        backgroundColor: 'rgb(13, 133, 121)',
                        // data: [24, 4, 18, 2, 4, 22, 2, 2, 0, 0, 0, 0]
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    }
                ]
            },
            chartOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom'
                    }
                },
                responsive: true,
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    },
                },
                tension: 0.4
            },
            zeroAllMonths: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            years: [],
        }
    },
    computed: {
        yearlyTotals: function(){

            let returnObj = {
                attendedUsers:  0,
                enrolledUsers:  0,
                webinarsEvents: 0
            };

            let i = 0;
            for(let key in this.monthlyTrends){
                let arr = this.monthlyTrends[key];

                if( key == 'attendedUsersCount_forYear_byMonth'){
                    if( arr.length === 0){
                        i++;
                    }
                    else {
                        for(let ii = 0; ii < arr.length; ii++){
                            let obj = arr[ii];

                            returnObj.attendedUsers += obj.count;

                            if( ii === arr.length - 1){ // end of loop
                                i++;
                            };
                        };
                    };
                }
                else
                if( key == 'enrolledUserCount_forYear_byMonth'){
                    if( arr.length === 0){
                        i++;
                    }
                    else {
                        for(let ii = 0; ii < arr.length; ii++){
                            let obj = arr[ii];

                            returnObj.enrolledUsers += obj.count;

                            if( ii === arr.length - 1){ // end of loop
                                i++;
                            };
                        };
                    };
                }
                else
                if( key == 'webinarsEventsCount_forYear_byMonth'){
                    if( arr.length === 0){
                        i++;
                    }
                    else {
                        for(let ii = 0; ii < arr.length; ii++){
                            let obj = arr[ii];

                            returnObj.webinarsEvents += obj.count;

                            if( ii === arr.length - 1){ // end of loop
                                i++;
                            };
                        };
                    };
                };

                // end of loop
                if( i === Object.keys(this.monthlyTrends).length){
                    this.$emit('UPDATE_yearlyTotals', returnObj);
                    return returnObj;
                };
            };
        }
    },
    created: function(){
        this.init();
    },
    methods: {
        init: async function(){
            this.init_years();
            this.set_data_inChartData();

            this.$emit('UPDATE_monthlyTrends_chartData', this.chartData);

            this.ready = true;
        },
        init_years: function(){
            let thisYear = new Date(Date.now()).getFullYear();
            for(let i = 0; i < 100; i++){ // set 100 years
                this.years.push(thisYear);
                thisYear--;
            };
        },
        set_chartDataToZero: function(){
            for(let i = 0; i < this.chartData.datasets.length; i++){
                let dataset = this.chartData.datasets[i];
                let data = dataset.data;

                // console.log('CHECK SAME',
                //     this.chartData.datasets[i].data === data
                // );
                //
                // console.log('CHECK ZEROS ARRAY',
                //     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] === this.zeroAllMonths
                // );

                // WORKS
                this.chartData.datasets[i].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                // this.chartData.datasets[i].data = this.zeroAllMonths;

                // FAILS
                // data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                // data = this.zeroAllMonths;
            };
        },
        set_data_inChartData: async function(){

            this.set_chartDataToZero();

            let attendedUsers_byMonth  = this.chartData.datasets[0].data;
            let enrolledUsers_byMonth  = this.chartData.datasets[1].data;
            let webinarsEvents_byMonth = this.chartData.datasets[2].data;

            let i = 0;
            for(let key in this.monthlyTrends){
                let arr = this.monthlyTrends[key];

                if( key == 'attendedUsersCount_forYear_byMonth'){
                    if( arr.length === 0){
                        i++;
                    }
                    else{
                        for(let ii = 0; ii < arr.length; ii++){
                            let obj = arr[ii];
                            let month_asIndex = Number(obj.month) - 1;

                            attendedUsers_byMonth[month_asIndex] = obj.count;

                            if( ii === arr.length - 1){ // End of loop
                                i++;
                            };
                        };
                    };
                }
                else
                if(key == 'enrolledUserCount_forYear_byMonth'){
                    if( arr.length === 0){
                        i++;
                    }
                    else{
                        for(let ii = 0; ii < arr.length; ii++){
                            let obj = arr[ii];
                            let month_asIndex = Number(obj.month) - 1;

                            enrolledUsers_byMonth[month_asIndex] = obj.count;

                            if( ii === arr.length - 1){ // End of loop
                                i++;
                            }
                        };
                    };
                }
                else
                if(key == 'webinarsEventsCount_forYear_byMonth'){
                    if( arr.length === 0){
                        i++;
                    }
                    else{
                        for(let ii = 0; ii < arr.length; ii++){
                            let obj = arr[ii];
                            let month_asIndex = Number(obj.month) - 1;

                            webinarsEvents_byMonth[month_asIndex] = obj.count;

                            if( ii === arr.length - 1){ // End of loop
                                i++;
                            };
                        };
                    };
                };

                // // END of loop
                // if( i === Object.keys(this.monthlyTrends).length){
                //
                // };
            };
        }
         // END set_data_inChartData()
    },
    watch: {
        chartData: function(after, before){
            this.$emit('UPDATE_monthlyTrends_chartData', this.chartData);
        },
        monthlyTrends: function(after, before){
            this.set_data_inChartData();
        },
        year_selected: function(after, before){
            this.$emit('UPDATE_year_selected', this.year_selected);
        }
    }
}
</script>

<style lang='scss'>

    .MonthlyTrend {

        background-color: white;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
        margin: 0 0 16px;
        padding: 16px 24px 16px 40px;
        position: relative;

        @media(max-width: 768px){
            width: 100%;
        }

        &:after {
            content: 'No. of Users';
            display: block;
            position: absolute;
            top: 50%;
            left: -8px;
            transform: rotate(-90deg);
            font-family: 'Roboto' 'sans-serif';
            font-size: 11px;
            font-weight: 400;
            color: rgb(102, 102, 102);
        }

        h2 {
            color: $secondary;
            font-size: 16px;
            margin-bottom: 16px;
            font-weight: 500;
            margin-left: -16px;
        }

        .flexedRow {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;

            .counts {
                display: flex;
                width: 75%;

                @media(max-width: 768px){
                    width: 100%;
                }

                p:not(:first-of-type) {
                    margin-left: 32px;
                }

                p {
                    color: rgb(116, 120, 141);
                    font-size: 14px;

                    &:first-of-type {
                        margin-left: 16px;
                    }

                    span {
                        font-size: 22px;
                        font-weight: 500;
                        margin-right: 8px;
                    }
                    &:nth-of-type(1) {
                        span {
                            color: rgb(13, 133, 121);
                        }
                    }
                    &:nth-of-type(2) {
                        span {
                            color: rgb(82, 204, 160);
                        }
                    }
                    &:nth-of-type(3) {
                        span {
                            color: rgb(235, 225, 17);
                        }
                    }
                }
            } // END counts

            .controls {
                width: 25%;
                margin-top: -32px;
                margin-bottom: 16px;

                @media(max-width: 768px){
                    width: auto;
                }

                .yearButton {
                    display: block;

                    .v-input__control {

                        display: flex;
                        flex-direction: row-reverse;

                        .v-input__slot {
                            background-color: white !important;
                            color: rgb(116, 120, 141);
                            font-size: 16px;
                            width: 120px;
                            height: 48px !important;
                            border: none;
                            border-radius: 6px;
                            box-shadow: inset 0 0 4px 0px rgba(116, 120, 141, 0.5);
                        }
                    }

                    .v-text-field__details {
                        display: none; // remove a vuetify default element that adds space under a selector
                    }
                }
            } // END controls
        }
    } // END MonthlyTrend
</style>
