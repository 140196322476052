<template>

    <div class='metrics'>
        <div
        v-for="(obj, index) in metrics"
        class='card'
        :metrics="obj"
        :dayRange="dayRange"
        :key="index"
        >
            <p class='amount'>{{ metrics[index].amount_end - metrics[index].amount_start }}</p>
            <p class='category'>{{ metrics[index].name }}</p>
            <p class='percent'>
                <v-icon>mdi-arrow-up-bold</v-icon>
                <span>{{ metrics[index].percentIncrease }}%</span> Increase in {{dayRange}}
                <span v-if='dayRange > 1'>Days</span>
                <span v-else>Day</span>
            </p>
        </div>
    </div>

</template>

<script>

export default {
    data: function(){
        return {
            ready: false,
            form: {
                date_start: null,
                date_end: null
            }
        }
    },
    props: {
        metrics: {
            type: Object,
            required: true,
            default: 'Need to pass an Object to Metrics component'
        },
        dayRange: {
            type: Number,
            required: true,
            default: 'Need to pass an Number to Metrics component'
        }
    }
}
</script>

<style scoped lang='scss'>

    .metrics {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 16px;
        margin: 0 0 16px;

        .card {
            flex: 1;
            background-color: white;
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
            padding: 16px 24px;
            min-width: 200px;

            p {
                color: rgb(116, 120, 141);
                font-size: 14px;
                margin-bottom: 0; // overrides v-application p rule
            }

            .amount {
                color: rgb(74, 80, 88);
                font-size: 22px;
                font-weight: 500;
            }

            .category {
                height: 40px;
                line-height: 16px;
            }

            .percent {

                .v-icon {
                    color: $secondary; // green
                    font-size: 16px;
                    margin-top: -4px;
                    margin-left: -2px;
                }

                span:first-of-type {
                    color: $secondary; // green
                }
            }
        }
    }
</style>
