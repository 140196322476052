<template>
    <div class="DatePicker">

        <div class="yearContainer">
            <p>Filter by year:</p>

            <v-select
            class='yearButton'
            :items="years"
            v-model='date_year'
            dense
            color='rgb(22, 163, 148)'
            ></v-select>
        </div>

        <!-- START pickersContainer -->
        <div class="pickersContainer">

            <p>or Choose Date Range:</p>

            <div class="flex_pickers">
                <!-- START menu_start -->
                <v-menu
                ref="menu_start"
                v-model="menu_start"
                :close-on-content-click="false"
                :return-value.sync="date_start"
                transition="scroll-y-transition"
                bottom
                offset-y
                min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        class='picker'
                        v-model="date_start"
                        label="Choose Start Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        color="rgb(13, 133, 121)"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                    v-model="date_start"
                    no-title
                    scrollable
                    color="rgb(13, 133, 121)"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                        text
                        color="rgb(13, 133, 121)"
                        @click="menu_start = false"
                        >
                        Cancel
                        </v-btn>
                        <v-btn
                        text
                        color="rgb(13, 133, 121)"
                        @click="$refs.menu_start.save(date_start)"
                        >
                        OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
                <!-- END menu_start -->

                <!-- START menu_end -->
                <v-menu
                ref="menu_end"
                v-model="menu_end"
                :close-on-content-click="false"
                :return-value.sync="date_end"
                transition="scroll-y-transition"
                bottom
                left
                offset-y
                min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        class='picker'
                        v-model="date_end"
                        label="Choose End Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        color="rgb(13, 133, 121)"
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                    v-model="date_end"
                    no-title
                    scrollable
                    color="rgb(13, 133, 121)"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                        text
                        color="rgb(13, 133, 121)"
                        @click="menu_end = false"
                        >
                        Cancel
                        </v-btn>
                        <v-btn
                        text
                        color="rgb(13, 133, 121)"
                        @click="$refs.menu_end.save(date_end)"
                        >
                        OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
                <!-- END menu_end -->
            </div>
            <!-- END flex_pickers -->
        </div>
        <!-- END pickersContainer -->
    </div>
    <!-- END DatePicker -->
</template>

<script>

export default {

    created: async function(){
        this.init();
    },
    data: function(){
        return {
            date_year: new Date(Date.now()).getFullYear(),
            menu_start: false,
            menu_end: false,
            ready: false,
            years: []
        }
    },
    props: {
        date_end: {
            type: String,
            required: true,
            default: 'Need a string for date_end'
        },
        date_start: {
            type: String,
            required: true,
            default: 'Need a string for date_start'
        }
    },
    methods: {
        init: async function(){
            this.init_years();
            this.ready = true;
        },
        init_years: function(){
            let thisYear = new Date(Date.now()).getFullYear();
            for(let i = 0; i < 100; i++){ // set 100 years
                this.years.push(thisYear);
                thisYear--;
            };
        },
    },
    watch: {
        date_start: function(after, before){
            this.$emit('UPDATE_date_start', this.date_start);
        },
        date_end: function(after, before){
            this.$emit('UPDATE_date_end', this.date_end);
        },
        date_year: function(after, before){
            this.date_start = `${after}-01-01`;
            this.date_end   = `${Number(after) + 1}-01-01`;
            this.$emit('UPDATE_date_start', this.date_start);
            this.$emit('UPDATE_date_end', this.date_end);
        }
    }
}
</script>

<style scoped lang='scss'>

    .DatePicker {
        display: flex;
        position: relative;
        padding: 16px 0;
        margin-bottom: -32px;

        @media(max-width: 576px){
            flex-wrap: wrap;
            padding: 0;
        }

        h1 {
            color: $secondary;
            font-size: 16px;
            font-weight: 500;
            position: absolute;
            left: 0;
            bottom: 16px;
        }

        p {
            color: rgb(102, 102, 102);
            font-size: 14px;
        }

        .yearContainer {
            display: flex;
            margin-right: 16px;

            @media(max-width: 768px){
                flex-flow: column;
            }
            @media(max-width: 576px){
                flex-wrap: wrap;
                margin-right: 0;
            }

            p {
                position: relative;
                top: -4px;
                margin-top: 16px;
                padding: 0 8px;

                @media(max-width: 768px){
                    margin-top: 6px;
                }
                @media(max-width: 576px){
                    text-align: right;
                }
            }

            .yearButton {
                margin-top: 16px;
                max-width: 120px;

                @media(max-width: 768px){
                    margin-top: 12px;
                }
            }
        }

        .pickersContainer {
            display: flex;

            @media(max-width: 768px){
                flex-flow: column;
            }
            @media(max-width: 576px){
                flex-wrap: wrap;
            }

            p {
                width: 88px;
                position: relative;
                top: -4px;

                @media(max-width: 768px){
                    top: 0;
                    width: 100%;
                }
                @media(max-width: 576px){
                    text-align: right;
                }
            }

            .flex_pickers {
                display: flex;
            }

            .picker {
                margin: 0 8px;
                max-width: 160px;
                min-width: 120px;

                @media(max-width: 576px){
                    max-width: 144px;
                    margin-left: 0;
                }
            }
        }
    }
</style>
