<template>
<div>
    <div v-if='ready' class="wrap">
        <br>
        <h1>Dashboard</h1>
        <hr>
        <div class="flex flex_DatePicker">
            <DatePicker
            :date_start='date_start'
            :date_end='date_end'
            @UPDATE_date_start='(given)=>this.date_start = given'
            @UPDATE_date_end='(given)=>this.date_end = given'
            />
            <div class="buttons">
                <v-btn class="submitButton" @click="event_showDataForRange">
                    <str index='buttons.submit'/>
                </v-btn>
            </div>
        </div>
        <br>
        <Metrics v-if='ready' :metrics='metrics' :dayRange='dayRange'/>
        <div class="flex flex_exportButton">
            <v-btn class='exportButton' @click='event_exportCSV_metrics'>
                <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                Export
            </v-btn>
        </div>
        <br><hr><br>

        <SpecialtyGraph :specialtyGraph='specialtyGraph'/>
        <div class="flex flex_exportButton">
            <v-btn class='exportButton' @click='event_exportCSV_speciality'>
                <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                Export
            </v-btn>
        </div>
        <br><hr><br>

        <MonthlyTrend
        :monthlyTrends='monthlyTrends'
        :year_selected='year_selected'
        @UPDATE_monthlyTrends_chartData='(given)=>this.monthlyTrends_chartData = given'
        @UPDATE_year_selected='(given)=>this.year_selected = given'
        @UPDATE_yearlyTotals='(given)=>this.yearlyTotals = given'
        />
        <div class="flex flex_exportButton">
            <v-btn class='exportButton' @click='event_exportCSV_monthlyTrend'>
                <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                Export
            </v-btn>
        </div>
        <br>

        <v-snackbar
        class='Snackbar_warning'
        v-model="snackbar"
        top
        light
        >
            <p class='Snackbar_text'>Cannot submit a Date Range with an End Date that comes before the Start Date.</p>
            <template v-slot:action="{ attrs }">
                <v-btn
                class='Snackbar_button'
                color="black"
                text
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
    <v-dialog v-model="show_loader" hide-overlay persistent width="300">
        <v-card color="primary" dark>
            <v-card-text>
                Loading... Please stand by
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import DatePicker from '@/components/Dashboard/DatePicker'
import Metrics from '@/components/Dashboard/Metrics'
import SpecialtyGraph from '@/components/Dashboard/SpecialtyGraph'
import MonthlyTrend from '@/components/Dashboard/MonthlyTrend'
import Exporter from '@/mixins/exporter.js'

export default {
    name: 'Dashboard',
    components: {
        DatePicker,
        Metrics,
        SpecialtyGraph,
        MonthlyTrend,
    },
    data: function(){
        return {
            date_end: null,
            date_start: null,
            dayRange: null,
            logs: null,
            metrics: null,
            monthlyTrends: null,
            monthlyTrends_chartData: null,
            ready: false,
            show_loader: !this.ready,
            snackbar: false,
            specialtyGraph: null,
            year_selected: new Date(Date.now()).getFullYear(),
            yearlyTotals: null
        }
    },
    created: function(){
        this.init();
    },
    methods: {
        event_exportCSV_metrics: async function(){
            // CREATE: starting table structure
            let table = [
                ['Metrics']
                ,[]
                ,['', 'Start Date', 'End Date']
                ,['Date Range', this.date_start, this.date_end]
                ,[]
                ,['Number of Days', this.dayRange]
                ,[]
                ,['Category', 'Total', '% Increase']
                ,['Registered Dental Professionals', this.metrics.registeredTotal.amount_end - this.metrics.registeredTotal.amount_start, this.metrics.registeredTotal.percentIncrease]
                ,['Webinars Conducted', this.metrics.webinarsConducted.amount_end - this.metrics.webinarsConducted.amount_start, this.metrics.webinarsConducted.percentIncrease]
                ,['Sample Requests', this.metrics.sampleRequests.amount_end - this.metrics.sampleRequests.amount_start, this.metrics.sampleRequests.percentIncrease]
                ,['VIP Opt-ins', this.metrics.vipOptIns.amount_end - this.metrics.vipOptIns.amount_start, this.metrics.vipOptIns.percentIncrease]
            ];
            // CREATE: CSV string
            let csvString = await Exporter.create_csvString_from2dArray({table: table});
            // EVENT: download CSV file
            Exporter.event_downloadCSVFile_fromCSVString({csvString: csvString, title: 'Metrics'});
        },
        event_exportCSV_monthlyTrend: async function(){
            // CREATE: starting table structure
            let table = [
                ['Monthly Trends']
                ,[]
                ,['Type', 'Webinars', 'Enrolled Users', 'Attended Users']
                ,[]
                ,['Year Total', this.yearlyTotals.webinarsEvents, this.yearlyTotals.enrolledUsers, this.yearlyTotals.attendedUsers]
                ,[]
                ,['Months']
                ,['January', this.monthlyTrends_chartData.datasets[2].data[0], this.monthlyTrends_chartData.datasets[1].data[0], this.monthlyTrends_chartData.datasets[0].data[0]]
                ,['February', this.monthlyTrends_chartData.datasets[2].data[1], this.monthlyTrends_chartData.datasets[1].data[1], this.monthlyTrends_chartData.datasets[0].data[1]]
                ,['March', this.monthlyTrends_chartData.datasets[2].data[2], this.monthlyTrends_chartData.datasets[1].data[2], this.monthlyTrends_chartData.datasets[0].data[2]]
                ,['April', this.monthlyTrends_chartData.datasets[2].data[3], this.monthlyTrends_chartData.datasets[1].data[3], this.monthlyTrends_chartData.datasets[0].data[3]]
                ,['May', this.monthlyTrends_chartData.datasets[2].data[4], this.monthlyTrends_chartData.datasets[1].data[4], this.monthlyTrends_chartData.datasets[0].data[4]]
                ,['June', this.monthlyTrends_chartData.datasets[2].data[5], this.monthlyTrends_chartData.datasets[1].data[5], this.monthlyTrends_chartData.datasets[0].data[5]]
                ,['July', this.monthlyTrends_chartData.datasets[2].data[6], this.monthlyTrends_chartData.datasets[1].data[6], this.monthlyTrends_chartData.datasets[0].data[6]]
                ,['August', this.monthlyTrends_chartData.datasets[2].data[7], this.monthlyTrends_chartData.datasets[1].data[7], this.monthlyTrends_chartData.datasets[0].data[7]]
                ,['September', this.monthlyTrends_chartData.datasets[2].data[8], this.monthlyTrends_chartData.datasets[1].data[8], this.monthlyTrends_chartData.datasets[0].data[8]]
                ,['October', this.monthlyTrends_chartData.datasets[2].data[9], this.monthlyTrends_chartData.datasets[1].data[9], this.monthlyTrends_chartData.datasets[0].data[9]]
                ,['November', this.monthlyTrends_chartData.datasets[2].data[10], this.monthlyTrends_chartData.datasets[1].data[10], this.monthlyTrends_chartData.datasets[0].data[10]]
                ,['December', this.monthlyTrends_chartData.datasets[2].data[11], this.monthlyTrends_chartData.datasets[1].data[11], this.monthlyTrends_chartData.datasets[0].data[11]]
            ];
            // CREATE: CSV string
            let csvString = await Exporter.create_csvString_from2dArray({table: table});
            // EVENT: download CSV file
            Exporter.event_downloadCSVFile_fromCSVString({csvString: csvString, title: 'MonthlyTrend'});
        },
        event_exportCSV_speciality: async function(){
            // CREATE: starting table structure
            let table = [
                ['Specialty Totals']
                ,[]
                ,['Profession', 'Number of Users', 'Number of Events Attended']
                ,['Dentists', this.specialtyGraph.amountOfUsersByProfession.dentist, this.specialtyGraph.attendedAmountByProfession.dentist]
                ,['Hygienists', this.specialtyGraph.amountOfUsersByProfession.dental_hygienist, this.specialtyGraph.attendedAmountByProfession.dental_hygienist]
                ,['Orthodontists', this.specialtyGraph.amountOfUsersByProfession.orthodontist, this.specialtyGraph.attendedAmountByProfession.orthodontist]
                ,['Periodontists', this.specialtyGraph.amountOfUsersByProfession.periodontist, this.specialtyGraph.attendedAmountByProfession.periodontist]
                ,['Other', this.specialtyGraph.amountOfUsersByProfession.other, this.specialtyGraph.attendedAmountByProfession.other]
            ];
            // CREATE: CSV string
            let csvString = await Exporter.create_csvString_from2dArray({table: table});
            // EVENT: download CSV file
            Exporter.event_downloadCSVFile_fromCSVString({csvString: csvString, title: 'Specialties'});
        },
        event_showDataForRange: async function(){
            return new Promise(async(resolve, reject)=>{
                await this.pass_dashboardMetrics_toMetrics()
                .then(()=>{
                    this.set_dayRange(this.date_start, this.date_end);
                    resolve();
                })
                .catch((err)=>{
                    reject();
                });
            });
        },
        get_metrics: async function(){
            return new Promise((resolve)=>{
                let self = this;
                self.sendRequest('get','/api/getMetrics').then((response)=>{
                    this.metrics = response.data;
                    resolve();
                });
            });
        },
        init: async function(){
            this.init_dateStart();
            this.init_dateEnd();
            this.set_dayRange(this.date_start, this.date_end);
            this.pass_yearSelected_forMonthlyTrends();
            await this.pass_dashboardMetrics_toMetrics();

            this.ready = true;
        },
        init_dateEnd: function(){
            let date      = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.date_end = date;
        },
        init_dateStart: function(){
            let msInADay      = 86400000;
            let msIn30Days    = msInADay * 30;
            let ms30DaysAgo   = Date.now() - msIn30Days;
            let formattedDate = new Date(ms30DaysAgo).toLocaleString('sv').split(' ')[0];
            this.date_start   = formattedDate;
        },
        pass_dashboardMetrics_toMetrics: async function(){
            return new Promise((resolve, reject)=>{
                let self = this
                let url = '/api/dashboardMetrics';
                if( this.date_start > this.date_end){
                    this.snackbar = true;
                    reject();
                    return;
                }
                let sendObj = {
                    date_start: this.date_start,
                    date_end:   this.date_end
                }

                self.sendRequest('post', url, sendObj).then((response)=>{
                    this.logs           = response.data.logs;
                    this.metrics        = response.data.metrics;
                    this.specialtyGraph = response.data.specialtyGraph;
                    this.set_percentIncrease_onMetrics();
                    resolve();
                })
            })
        },
        pass_yearSelected_forMonthlyTrends: function(){
            return new Promise((resolve)=>{
                let self = this;
                let url = '/api/dashboardMetrics_monthlyTrends';
                let sendObj = {
                    year_selected: this.year_selected,
                }
                // PASS
                self.sendRequest('POST', url, sendObj).then((response)=>{

                    // console.log(response.data, 'DATA OBJ BACK FROM pass_yearSelected_forMonthlyTrends');

                    // SET: monthlyTrends data value
                    this.monthlyTrends = response.data;
                    resolve();
                })
            })
        },
        set_dayRange: function(date_start, date_end){
            /*
            For date arguments, expecting something like '08-11-2022 00:00:00'.
            Can omit the time part, but want month-day-year to use JS methods.
            Example:
                Passing:
                    date_start = '2022-07-14 00:00:00';
                    date_end   = '2022-08-11 00:00:00';
                Result:
                    28
            */
            // MODIFY: expected date format into JS date format
            let date_start_JS = new Date(date_start);
            let date_end_JS   = new Date(date_end);
            // MODIFY: JS date format into milliseconds since 1970
            let date_start_MS = date_start_JS.getTime();
            let date_end_MS   = date_end_JS.getTime();
            // CALCULATE: difference with MS, then convert MS to days
            let difference_MS   = date_end_MS - date_start_MS;
            let difference_days = difference_MS/86400000; // 86400000 is MS in a day
            // SET: dayRange in data obj
            this.dayRange = difference_days;
        },
        set_percentIncrease_onMetrics: function(){
            // LOOP:
            let i = 0;
            for(let key in this.metrics){
                let obj = this.metrics[key];
                // CALCULATE:
                let percentIncrease = null;
                if( obj.amount_start > 0){
                    percentIncrease = (obj.amount_end - obj.amount_start) / obj.amount_start * 100;
                }
                else{
                    percentIncrease = obj.amount_end * 100;
                };
                // SET:
                this.metrics[key].percentIncrease = parseInt(percentIncrease);
                i++;
                // END OF LOOP:
                // if( i === Object.keys(this.metrics).length -1){
                //     console.log('result', this.metrics);
                // };
            };
        },
    },
    watch: {
        year_selected: function(){
            this.pass_yearSelected_forMonthlyTrends();
        },
        ready: function(after, before){
            return this.show_loader = !after
        }
    }
}
</script>

<style scoped lang='scss'>

    .wrap {
        position: relative;
        background-color: rgb(245, 246, 248);
        padding: 0 12%;

        @media(max-width: 1400px){
            padding: 0 8%;
        }
        @media(max-width: 1200px){
            padding: 0 4%;
        }
        @media(max-width: 768px){
            padding: 0 6%;
        }

        .flex {
            display: flex;
            flex-wrap: wrap;
        }

        .flex_DatePicker {
            position: relative;
            justify-content: flex-end;

            @media(max-width: 576px){
                margin-bottom: 64px;
            }
            @media(max-width: 992px){
                margin-bottom: 16px;
            }

            h1 {
                width: 10%;
                display: flex;
                flex-flow: column;
                justify-content: flex-end;
                margin-top: 8px;
                color: $secondary;
                font-size: 20px;
                font-weight: 500;

                @media(max-width: 1200px){
                    width: 100%;
                }
            }

            .DatePicker {
                width: 80%;
                display: flex;
                justify-content: flex-end;

                @media(max-width: 1200px){
                    width: auto;
                }
                @media(max-width: 992px){
                    width: 100%;
                }
                @media(max-width: 576px){
                    width: 100%;
                }
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                width: 10%;

                @media(max-width: 1200px){
                    width: 20%;
                }
                @media(max-width: 992px){
                    width: 100%;
                    justify-content: flex-end;
                }

                .submitButton {
                    color: white;
                    background-color: $secondary;
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: unset !important; // removes vuetify uppercase rule
                    margin-top: 24px;
                }

                .exportButton {
                    margin: 24px 0 0 8px;
                    background-color: $primary_light_1;
                    color: white;
                    text-transform: unset !important; // removes vuetify uppercase rule
                }
            }
        }

        .flex_exportButton {
            justify-content: flex-end;

            .exportButton {
                background-color: $primary_light_1;
                color: white;
                text-transform: none;
            }
        }

        .flex_SpecialtyGraph_RecentActivity {
            gap: 16px;
            margin: 0 0 16px;

            .SpecialtyGraph,
            .RecentActivity {
                background-color: white;
                border-radius: 4px;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
                width: calc(50% - 8px);

                @media(max-width: 768px){
                    width: 100%;
                }
            }
        }
    }
</style>
