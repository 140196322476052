<template>

    <div class="MonthlyTrend">
        <h2>Specialty</h2>
        <Bar
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        />
    </div>

</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {

    components: { Bar },

    props: {
        specialtyGraph: {
            type: Object,
            required: true,
            default: 'Need data object prop to feed Specialty Graph'
        },
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 300
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            chartData: {
                labels: [ 'Dentists', 'Hygienists', 'Periodontists', 'Orthodontists', 'Other' ],
                datasets: [
                    {
                        label: 'Registered Users',
                        backgroundColor: 'rgb(22, 163, 148)', // $secondary/green
                        data: [38, 6, 6, 58, 23]
                    },
                    {
                        label: 'Events Attended',
                        backgroundColor: 'rgb(124, 185, 201)', // pale blue
                        data: [1, 2, 2, 12, 7]
                    }
                ]
            },
            chartOptions: {
                plugins: {
                    legend: {
                        position: 'bottom'
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    },
                }
            }
        }
    },
    created: function(){
        this.set_data_inChartData();
    },
    methods: {
        set_data_inChartData: function(){

            let registeredUsersArray = this.chartData.datasets[0].data;
            let attendedUsersArray   = this.chartData.datasets[1].data;

            for(let key in this.specialtyGraph.amountOfUsersByProfession){
                let obj = this.specialtyGraph.amountOfUsersByProfession[key];

                if( key === 'dentist'){
                    registeredUsersArray[0] = obj
                }
                else
                if( key === 'dental_hygienist'){
                    registeredUsersArray[1] = obj;
                }
                else
                if( key === 'periodontist'){
                    registeredUsersArray[2] = obj;
                }
                else
                if( key === 'orthodontist'){
                    registeredUsersArray[3] = obj;
                }
                else
                if( key === 'other'){
                    registeredUsersArray[4] = obj;
                };
            };

            for(let key in this.specialtyGraph.attendedAmountByProfession){
                let obj = this.specialtyGraph.attendedAmountByProfession[key];

                if( key === 'dentist'){
                    attendedUsersArray[0] = obj
                }
                else
                if( key === 'dental_hygienist'){
                    attendedUsersArray[1] = obj;
                }
                else
                if( key === 'periodontist'){
                    attendedUsersArray[2] = obj;
                }
                else
                if( key === 'orthodontist'){
                    attendedUsersArray[3] = obj;
                }
                else
                if( key === 'other'){
                    attendedUsersArray[4] = obj;
                };
            };
        }
    }
}
</script>

<style scoped lang='scss'>

    .SpecialtyGraph {
        padding: 16px 40px;
        position: relative;

        &:after {
            content: 'No. of Users';
            color: rgb(102, 102, 102);
            display: block;
            font-family: 'Roboto' 'sans-serif';
            font-size: 11px;
            font-weight: 400;
            position: absolute;
            top: 50%;
            left: -8px;
            transform: rotate(-90deg);
        }

        h2 {
            color: $secondary;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 16px;
            margin-left: -16px;
        }
    }
</style>
